<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import { Game } from "@/types";

const { open } = useAppModals();
const { data: appInitData } = useAppInitData();

const props = withDefaults(
	defineProps<{
		category?: string;
		title?: string;
		icon?: string;
		games: Game[];
		rowsPerSlide?: number;
		isShowAll?: boolean;
	}>(),
	{ rowsPerSlide: 1, isShowAll: true }
);

const { t } = useT();
const router = useRouter();

const sliderRef = ref();

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto", spacing: 0 }
};

const preparedGames = computed(() =>
	props.games.reduce<Array<Array<Game>>>((acc, item, index) => {
		const chunkIndex = Math.floor(index / props.rowsPerSlide);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, [])
);

const handleNavigateToGames = () => {
	if (!props.category) {
		return;
	}

	if (props.category === "recent-games") {
		router.push("/issues/favorites/");
		return;
	}

	router.push(`/issues/${props.category}/`);
};

const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
const { handleOpenGame } = useOpenGame(open);

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		const lastGames = appInitData.value?.lastGames || [];
		if (lastGames.includes(game.id)) {
			appInitData.value?.lastGames &&
				(appInitData.value.lastGames = [game.id as number, ...lastGames.filter((el) => el !== game.id)]);
		} else {
			appInitData?.value?.lastGames && (appInitData.value.lastGames = [game.id as number, ...lastGames]);
		}
		handleOpenGame(game.slug);
	}
};

watch(
	() => props.games.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<section>
		<div class="header">
			<AText v-if="title" class="title text-cannes" variant="tamale" as="h3">
				<span>{{ title }}</span>
				<ASvg v-if="icon" :name="icon" class="text-caracas icon" />
			</AText>
			<AText
				v-if="isShowAll"
				variant="toledo"
				:modifiers="['underline']"
				class="show-all"
				@click="handleNavigateToGames"
				>{{ t("Show all") }}</AText
			>
		</div>

		<ASlider ref="sliderRef" :options="sliderOptions">
			<template v-if="rowsPerSlide === 1">
				<MGame
					v-for="game in games"
					:key="game.id"
					:game="game"
					class="keen-slider__slide"
					@toggle-favorite="handleToggleToFavoriteClick(game)"
					@play="handlePlayClick(game)"
				/>
			</template>
			<template v-else>
				<div
					v-for="(item, index) in preparedGames"
					:key="`${category}-${index}`"
					class="keen-slider__slide slide-column"
				>
					<MGame
						v-for="game in item"
						:key="game.id"
						:game="game"
						@toggle-favorite="handleToggleToFavoriteClick(game)"
						@play="handlePlayClick(game)"
					/>
				</div>
			</template>
		</ASlider>
	</section>
</template>
<style lang="scss" scoped>
section {
	padding: gutter(3) 0;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding: gutter(3) gutter(2);
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.icon {
		font-size: 32px;

		@include media-breakpoint-down(md) {
			font-size: 26px;
		}
	}

	.show-all {
		color: var(--catar);
		cursor: pointer;

		@include media-breakpoint-down(md) {
			font-size: 14px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.title {
		display: flex;
		align-items: center;
		gap: gutter(1);

		@include media-breakpoint-down(md) {
			font-size: 24px;
			line-height: 30px;
		}

		.icon {
			transform-origin: center;
			animation: swingAndScale 3s ease-in-out infinite;
		}
	}

	&:deep(.keen-slider) {
		padding-top: gutter(3);

		&::before {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			top: 24px;
			bottom: 0;
			width: 113px;
			background: var(--gaya);
			z-index: 1;

			@include media-breakpoint-down(md) {
				content: none;
			}
		}

		@include media-breakpoint-down(md) {
			padding-top: gutter(2.5);

			&:not([data-keen-slider-disabled]) {
				overflow: visible;
			}
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: calc(210px + 8px);
		padding-right: gutter(1);

		@include media-breakpoint-down(md) {
			width: calc(150px + 12px);
			padding-right: gutter(1.5);

			&:last-of-type {
				padding-right: 0;
				width: 150px;
			}
		}

		&.slide-column {
			display: flex;
			flex-direction: column;
			gap: gutter(1.5);

			@include media-breakpoint-down(md) {
				gap: gutter(1.5);
			}
		}
	}
}

@keyframes swingAndScale {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(1.4);
	}

	50% {
		transform: rotate(-10deg);
	}

	75% {
		transform: rotate(10deg);
	}

	100% {
		transform: scale(1);
	}
}
</style>
